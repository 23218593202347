<template>
  <router-view />

  <a
    href="#"
    class="back-to-top d-flex align-items-center justify-content-center"
  >
    <i class="fa-solid fa-chevron-up"></i>
  </a>

  <notifications position="bottom center" />
</template>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

router-view {
  width: 100%;
}
</style>
