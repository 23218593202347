import { createStore } from "vuex";

export default createStore({
  state() {
    return {
      count: JSON.parse(localStorage.getItem("shortlistCount")) || 0,
    };
  },
  mutations: {
    setShortlistCount(state, count) {
      console.log("Mutation setShortlistCount called with count:", count);
      state.count = count;
      localStorage.setItem("shortlistCount", JSON.stringify(count));
    },
  },
  actions: {
    updateShortlistCount({ commit }) {
      const shortlist = JSON.parse(localStorage.getItem("shortlist")) || [];
      console.log(
        "Action updateShortlistCount called. Shortlist length:",
        shortlist.length
      );
      commit("setShortlistCount", shortlist.length);
    },
  },
  getters: {
    count(state) {
      return state.count;
    },
  },
});
