import axios from "axios";
import { Auth } from "aws-amplify";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import "nprogress/nprogress.css";

import "selectize/dist/css/selectize.default.css"; // Ensure this line is included

import $ from "jquery";

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);
import store from "./store";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "vue-multiselect/dist/vue-multiselect.min.css"; // Import styles
import Notifications from "@kyvg/vue3-notification";

let app = createApp(App)
  .use(LoadingPlugin, { loader: "dots", color: "#355b7c" })
  .use(router)
  .use(Notifications)
  .use(store)
  .component("QuillEditor", QuillEditor);

app.config.globalProperties.$ = $; // Add jQuery as a global variable

app.mount("#app");

axios.defaults.headers.common["Content-Type"] = "application/json";
